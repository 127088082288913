export const HotelXFolio = `
<!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
    <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Poppins" />
      <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="format-detection" content="date=no" />
      <meta name="format-detection" content="address=no" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="x-apple-disable-message-reformatting" />


      <style type="text/css" media="screen">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&family='Poppins':ital,wght@0,400;0,700;1,400;1,700&family=Raleway:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
           @media screen and (min-width: 600px) {
             .document-content {

               padding: 0 !important;
               justify-content: center !important;
               page-break-after:always;
       
              }
           }
           @media screen and (min-width: 992px) {
            .document-content {

              padding: 0 !important;
              justify-content: center !important;
              margin: 0 auto;
              max-width: 800px;
               page-break-after:always;
             }
          }
            @media print 
            {
                @page {
                size: A4;
                }
                
            }
          body  
{ 
    /* this affects the margin on the content before sending to printer */ 
    margin: 0px;  
}
          * {
              font-family: 'Poppins';
              -webkit-print-color-adjust: exact;
              -webkit-text-size-adjust:none;
          }

        .row-empty{
         /* visibility:collapse; */
visibility:collapse;

       }
.header-right {
    color: white !important;
    justify-content: center;
    padding:12px;
}

        #booking {
            font-family: 'Poppins', Helvetica, sans-serif;
            border-collapse: collapse;
            width: 100%;
            margin-top: 20px;
        }

        #booking td,
        #booking th {
            border: 2px solid #2c2c2c;
            padding: 8px;
            font-size: 18px;
            text-align: center;
        }

        #booking tr:nth-child(even) {
            background-color: #2c2c2c;
            color: #2c2c2c;
        }
        #booking th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #2c2c2c;
            color: white;
            font-weight: 200;
            font-size: 18px;
            text-align: center;
        }


        #description th {
            border: 1px solid #ececec;
        }

        #description tr:nth-child(even) {
            background-color: #fff;
        }

        #description tr:nth-child(odd) {
            background-color: #fff;
        }

    

        #total {
            font-family: 'Poppins', Helvetica, sans-serif;
            border-collapse: collapse;
        }

        #total td,
        #total th {
            border: 2px solid #fff;
            padding: 8px;
            font-size: 18px;
        }

        #total tr:nth-child(even) {
            background-color: #fff;
            color: #2c2c2c;
        }

        #total tr:nth-child(odd) {
            background-color: #fff;
            color: #2c2c2c;
        }

        #total th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #fff;
            color: #2c2c2c;
            font-weight: 200;
            font-size: 18px;
        }

        #total tfoot tr th {
            background-color: #2c2c2c !important;
        }

        /* .header-center{
            display:table-header-group;
        } */

        table{
            width: 100%;
        }
        table.report-container {
            page-break-after:always;
        }
        thead.report-header {
            display:table-header-group;
        }
        /* tfoot.report-footer {
            display:table-footer-group;
        }  */
    </style>

</head>

<body style="-webkit-text-size-adjust:none; 
background-color: white;
-webkit-print-color-adjust: exact; ">

<style type="text/css" media="print">
@page 
{
    size: A4;   /* auto is the initial value */
    margin: 0mm;  /* this affects the margin in the printer settings */
}
</style>



<div class="document-content" style="background:#fff; font-family:'Poppins';">

    <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="padding:12px; font-family:'Poppins';">
        <thead>
            <tr>
               
                    <td style="width:100%; display:flex; justify-content: center; margin-bottom: 12px;">

                            <img src=!!HotelImage!! width="35%" style="width: 100%;
                            max-width: 125px;" >
                        </td>

              </tr>
              <tr>
               
                <td style="width:100%;  display: flex; justify-content: center; text-align: center;">
                    <div>

                        <!-- <div style=" font-size: 16px; font-weight: 500;">!!FOLIO!!</div> -->
                        <!-- <div style="font-size: 10px; font-weight: 300; line-height: 1.5;">
                            !!FolioNo!!
                        </div>   -->
            
                        <div style="font-size: 12px; font-weight: 600; line-height: 1.5; margin-bottom: 12px;">
                            !!HotelName!!
                        </div>    
                        
                        <div style="font-size: 12px; font-weight: 400; line-height: 1.5;">
                            (SST Reg No: !!SSTRegNo!!)
                        </div>
                        <div style="font-size: 12px; font-weight: 400; line-height: 1.5;  margin-bottom: 12px;">
                            (TTX Reg No: !!TTXRegNo!!)
                        </div>
                        <div style="font-size: 14px; font-weight: 700; line-height: 1.5;">
                            !!FOLIO!!
                        </div>
                    </div>
                    </td>
          </tr>
          <tr>
            <td style="width:100%;  display: flex;justify-content: end; ">
                <div>

                    <!-- <div style=" font-size: 16px; font-weight: 500;">!!FOLIO!!</div> -->
                    <!-- <div style="font-size: 10px; font-weight: 300; line-height: 1.5;">
                        !!FolioNo!!
                    </div>   -->
        
                    <div style="font-size: 12px; font-weight: 600; line-height: 1.5; margin-bottom: 12px;">
                        !!FolioNo!!
                    </div>    
                    
                    <div style="font-size: 10px; font-weight: 400; line-height: 1.5;  margin-bottom: 12px;">
                        Date: !!Date!!
                    </div>
                  
                </div>
                </td>
          </tr>
        </thead>


    
        <tbody>
            <tr style= "break-inside:auto;>
                <td class="report-content-cell" style= "white-space: normal;">
                    <div class="main">
                        <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="padding:12px;">
                            <tr>
                                <td style="font-size:12px; font-weight: 300;">
                                Guest Name
                                </td>
                                <td></td>
                                !!eInvoiceQR!!
                            </tr>
        
                            <tr>
                                <td style="font-size:12px; font-weight: 600; color:#2c2c2c; width: 55%">
                                !!GuestName!!
                                </td>
                            </tr>
        
                            <tr style="margin-bottom: 6px;">
                                <td style="font-size:10px; font-weight: 400; line-height: 18px;">
                                    !!AgentName!!
                                </td>
                        
                                <td style="font-size:10px; font-weight: 400;   line-height: 18px;">
                                    IC : !!IdentityCard!!
                                    </td>
                                <td >
                                    
                                </td>
                            </tr>
        
                            <tr style="margin-bottom: 6px;">
                                <td style="font-size:10px; font-weight: 400;   line-height: 18px;">
                                    Company Name : !!BeneficiaryName!!
                                </td>
                                <td style="font-size:10px; font-weight: 400;  line-height: 18px;">
                                    Phone : !!GuestMobileNo!!
                                </td>
        
                            </tr>
                        
                            <tr style="margin-bottom: 6px;">
                                <td style="font-size:10px; font-weight: 400;   line-height: 18px;">
                                Address : !!GuestAddress!!
                                </td>
                            
                            
                                <td style="font-size:10px; font-weight: 400; vertical-align: top; width: 30%">
                                    Email : !!GuestEmail!!
                                </td>
                            </tr>
                        </table>

                      <table width="100%" border="0" cellspacing="0" cellpadding="0"  style="margin-bottom: 12px;" >
                            <tr class="" style="  font-family: 'Poppins';
                                        -webkit-print-color-adjust: exact;
                                        -webkit-text-size-adjust:none;    background-color: #ececec;
                                        color: #2c2c2c;
                                        font-size: 10px;
                                        line-height: 28px;
                                    ;
                                    text-align: center;">
                                <th style="font-weight: 500;">Arrival Date</th>
                                <th style="font-weight: 500;">Departure Date</th>
                                <th style="font-weight: 500;">Room No.</th>
                                <th style="font-weight: 500;">Booking No.</th>
                                <th style="font-weight: 500;">Cashier Name</th>
                            </tr>

                            <tr  style="    background-color:#ffff;
                                    font-family: 'Poppins';
                                        -webkit-print-color-adjust: exact;
                                        -webkit-text-size-adjust:none;
                                        color: #2c2c2c;
                                        font-size: 10px;
                                        line-height: 28px;
                                    ;
                                    text-align: center;">
                                <td><div style="line-height: 14px;">
                                !!ArrivalDate!! 
                                </div>
                                <div style="color:#2c2c2c; font-size: 10px; line-height: 14px;">
                                !!ArrivalTime!!
                                    </div>
                                </td>
                                <td><div style="line-height: 14px;">
                                !!DepartureDate!! 
                                </div>
                                <div style="color:#2c2c2c; font-size: 10px; line-height: 14px;">
                                !!DepartureTime!!
                                    </div>
                                </td>
                                <td>!!RoomNo!!</td>
                                <td>!!BookingNo!!</td>
                                <td>!!CashierName!!</td>
                            </tr>
                        </table>

                        <table width="100%" border="0" cellspacing="0" cellpadding="0" id="description" >
                            <tr
                            style="
                            font-family: 'Poppins';
                                -webkit-print-color-adjust: exact;
                                -webkit-text-size-adjust:none;
                                background-color: #ececec;
                                        color: #2c2c2c;
                            font-size: 10px;
                            line-height: 28px;
                            text-align: center;
                            "
                            >
                            <th style="font-weight: 500;width:150px;">Date</th>
                            <th style="font-weight: 500; width:315px; ">Description</th>
                            <th style="font-weight: 500;  width:10%;"></th>
                            <th style="font-weight: 500;  width:95px;">Ref No.</th>
                            <th style="font-weight: 500;  width:100px;">Total</th>
					
                            </tr>
                            
                            </tr>
                            !!NewItem!!
                            
                        </table>

                        !!AmountItem!!

                        !!ExternalOutlet!!

                        <table width="100%" border="0" cellspacing="0" cellpadding="0"  >
                        <tr
                        style="
                        font-family: 'Poppins';
                            -webkit-print-color-adjust: exact;
                            -webkit-text-size-adjust:none;
                            background-color: #FFFFFF;
                                    color: #FFFFFF;
                        font-size: 10px;
                        line-height: 28px;
                        text-align: center;
                        "
                        >
                        <th style="  background-color: #FFFFFF;
                        color: #FFFFFF; width:160px;"></th>
                        <th style="  background-color: #FFFFFF;
                        color: #FFFFFF; width:315px; "></th>
                         <th style="  background-color: #FFFFFF;
                        color: #FFFFFF; width:30px;"></th>
                        <th style="  background-color: #FFFFFF;
                        color: #FFFFFF; width:160px;"></th>
                        <th style="  background-color: #FFFFFF;
                        color: #FFFFFF; width:120px;"></th>
                
                        </tr>
                        
                        </tr>
                        !!NewItem2!!
                        
                    </table>
						
						    <div style="width: 50%;  margin: 4px; padding: 6px;">
                                <div style="display: flex; margin-bottom: 8px;">
                                    <img src="${require('../../../../../assets/images/Document/mobile.png')}" style=" width: 20px;  margin-right: 10px;">
                                    <span style="vertical-align: -webkit-baseline-middle;font-size: 10px;">!!HotelMobileNo!!</span>
                                </div>
                            
                                <div style="margin-bottom: 10px;">
                                    <div style="display: flex; margin-bottom: 8px;">
                                    <img src="${require('../../../../../assets/images/Document/email.png')}" style=" width: 20px;  margin-right: 10px;">
                                        <span style="vertical-align: -webkit-baseline-middle;font-size: 10px; white-space: unset;">!!HotelEmail!!</span>
                                    </div>
                                    <div style="display: flex; margin-bottom: 8px;">
                                        <img  src="${require('../../../../../assets/images/Document/website.png')}" style=" width: 20px;  margin-right: 10px;">
                                        <span style="vertical-align: -webkit-baseline-middle;font-size: 10px; white-space: unset;">!!HotelWebsite!!</span>
                                    </div>
                            
                            
                                    <div style="width:100%;">
                                        <img src="${require('../../../../../assets/images/Document/location.png')}" style=" width: 20px; height: auto; float: left; margin-right: 10px;">
                                        <div style="vertical-align: -webkit-baseline-middle;font-size: 10px;  white-space: unset;">
                                            !!HotelAddress!!              
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="width:99%;
                                vertical-align: -webkit-baseline-middle;
                                font-size: 10px;
                                white-space: unset;
                                margin-left: 15px;
                                font-family: 'Poppins';
                                -webkit-print-color-adjust: exact;
                                -webkit-text-size-adjust:none;
                                background-color: #fff;
                                color:'#212121';
                                line-height: 15px;">
                                !!InvoiceRemark!!
                            </div>                    
                        <div>
                        </div>

                        <div style="font-style: italic; font-weight: 500; color: #2c2c2c; font-size: 12px; text-align: center; margin-top: 8px;">
                            Thank you for Staying with us
                        </div>
                        <div>
                        </div>
                        <div style=" display: flex; margin-top: 30px;background-color: #ececec;
                 padding:12px; ">
                            <span style="color:#2c2c2c; font-size: 12px; flex: 1; font-weight: 300;">
                                This !!FOLIO!! is computer-generated, no signature is required.
                            </span>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

</body>

</html>`
