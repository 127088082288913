import React, { lazy } from 'react'


const BusinessInsightList = lazy(() =>
  import('./BusinessInsightList').then(module => ({
    default: module.BusinessInsightList,
  }))
)
// const BusinessAnalytics = lazy(() =>
//   import('./BIAnalytics/BusinessInsightAnalytics').then(module => ({
//     default: module.BusinessInsightAnalytics,
//   }))
// )
const BusinessInsightAnalytics = lazy(() =>
  import('./BIAnalytics/BusinessInsightAnalytics').then(module => ({
    default: module.BusinessInsightAnalytics,
  }))
)
const BusinessInsightDigitalDocument = lazy(() =>
  import('./BIDigitalDocument/BusinessInsightDigitalDocument').then(module => ({
    default: module.BusinessInsightDigitalDocument,
  }))
)
const BusinessInsightDigitalReport = lazy(() =>
  import('./BIDigitalReport/BusinessInsightDigitalReport').then(module => ({
    default: module.BusinessInsightDigitalReport,
  }))
)
const CashierSummary = lazy(() =>
  import('./BIDigitalReport/CashierSummary').then(module => ({
    default: module.CashierSummary,
  }))
)
const CashierDetailListing = lazy(() =>
  import('./BIDigitalReport/CashierDetailListing').then(module => ({
    default: module.CashierDetailListing,
  }))
)
const SalesAndServicesListing = lazy(() =>
  import('./BIDigitalReport/SalesAndServicesListing').then(module => ({
    default: module.SalesAndServicesListing,
  }))
)
const TransactionLedger = lazy(() =>
  import('./BIDigitalReport/TransactionLedger').then(module => ({
    default: module.TransactionLedger,
  }))
)
const BookingStatus = lazy(() =>
  import('./BIDigitalReport/BookingStatus').then(module => ({
    default: module.BookingStatus,
  }))
)
const ManagerReport = lazy(() =>
  import('./BIDigitalReport/ManagerReport').then(module => ({
    default: module.ManagerReport,
  }))
)
const ManagerReportByFinancial = lazy(() =>
  import('./BIDigitalReport/ManagerReportByFinancial').then(module => ({
    default: module.ManagerReportByFinancial,
  }))
)
const DownloadedGLListing = lazy(() =>
  import('./BIDigitalReport/DownloadedGLListing').then(module => ({
    default: module.DownloadedGLListing,
  }))
)
const ProductionAnalysisRoomNo = lazy(() =>
  import('./BIDigitalReport/ProductionAnalysisRoomNo').then(module => ({
    default: module.ProductionAnalysisRoomNo,
  }))
)
const ProductionAnalysisRateCode = lazy(() =>
  import('./BIDigitalReport/ProductionAnalysisRateCode').then(module => ({
    default: module.ProductionAnalysisRateCode,
  }))
)
const ProductionAnalysisRoomType = lazy(() =>
  import('./BIDigitalReport/ProductionAnalysisRoomType').then(module => ({
    default: module.ProductionAnalysisRoomType,
  }))
)
const ProductionAnalysisTAGov = lazy(() =>
  import('./BIDigitalReport/ProductionAnalysisTAGov').then(module => ({
    default: module.ProductionAnalysisTAGov,
  }))
)
const ProductionAnalysisSegment = lazy(() =>
  import('./BIDigitalReport/ProductionAnalysisSegment').then(module => ({
    default: module.ProductionAnalysisSegment,
  }))
)
const ProductionAnalysisSource = lazy(() =>
  import('./BIDigitalReport/ProductionAnalysisSource').then(module => ({
    default: module.ProductionAnalysisSource,
  }))
)
const ProductionAnalysisState = lazy(() =>
  import('./BIDigitalReport/ProductionAnalysisState').then(module => ({
    default: module.ProductionAnalysisState,
  }))
)
const ProductionAnalysisBySalesChannelReport = lazy(() =>
  import('./BIDigitalReport/ProductionAnalysisBySalesChannelReport').then(module => ({
    default: module.ProductionAnalysisBySalesChannel,
  }))
)

const DebtorLedgerDetail = lazy(() =>
  import('./BIDigitalReport/DebtorLedgerDetail').then(module => ({
    default: module.DebtorLedgerDetail,
  }))
)
const DebtorStatus = lazy(() =>
  import('./BIDigitalReport/DebtorStatus').then(module => ({
    default: module.DebtorStatus,
  }))
)

const DebtorLedger = lazy(() =>
  import('./BIDigitalReport/DebtorLedger').then(module => ({
    default: module.DebtorLedger,
  }))
)

const DebtorAllocation = lazy(() =>
  import('./BIDigitalReport/DebtorAllocation').then(module => ({
    default: module.DebtorAllocation,
  }))
)
const DebtorAgingSummary = lazy(() =>
  import('./BIDigitalReport/DebtorAgingSummary').then(module => ({
    default: module.DebtorAgingSummary,
  }))
)
const DebtorAgingDetail = lazy(() =>
  import('./BIDigitalReport/DebtorAgingDetail').then(module => ({
    default: module.DebtorAgingDetail,
  }))
)
const StatementOfAccount = lazy(() =>
  import('./BIDigitalReport/StatementOfAccount').then(module => ({
    default: module.StatementOfAccount,
  }))
)
const HistoricalAndForecast = lazy(() =>
  import('./BIDigitalReport/HistoricalAndForecast').then(module => ({
    default: module.HistoricalAndForecast,
  }))
)
const RoomMaintenance = lazy(() =>
  import('./BIDigitalReport/RoomMaintenance').then(module => ({
    default: module.RoomMaintenance,
  }))
)

const Packages = lazy(() =>
  import('./BIDigitalReport/Packages').then(module => ({
    default: module.Packages,
  }))
)
const RoomStatus = lazy(() =>
  import('./BIDigitalReport/RoomStatus').then(module => ({
    default: module.RoomStatus,
  }))
)
const RoomStatusMovement = lazy(() =>
  import('./BIDigitalReport/RoomStatusMovement').then(module => ({
    default: module.RoomStatusMovement,
  }))
)
const InHouseGuest = lazy(() =>
  import('./BIDigitalReport/InHouseGuest').then(module => ({
    default: module.InHouseGuest,
  }))
)
const RefundableDepositListing = lazy(() =>
  import('./BIDigitalReport/RefundableDepositListing').then(module => ({
    default: module.RefundableDepositListing,
  }))
)
const SecurityDeposit = lazy(() =>
  import('./BIDigitalReport/SecurityDeposit').then(module => ({
    default: module.SecurityDeposit,
  }))
)
const RoomDiscrepancy = lazy(() =>
  import('./BIDigitalReport/RoomDiscrepancy').then(module => ({
    default: module.RoomDiscrepancy,
  }))
)
const TourismTaxDetail = lazy(() =>
  import('./BIDigitalReport/TourismTaxDetail').then(module => ({
    default: module.TourismTaxDetail,
  }))
)
const TourismTaxAnalysis = lazy(() =>
  import('./BIDigitalReport/TourismTaxAnalysis').then(module => ({
    default: module.TourismTaxAnalysis,
  }))
)
const TourismTaxForm03 = lazy(() =>
  import('./BIDigitalReport/TourismTaxForm03').then(module => ({
    default: module.TourismTaxForm03,
  }))
)
const TransactionCancel = lazy(() =>
  import('./BIDigitalReport/TransactionCancel').then(module => ({
    default: module.TransactionCancel,
  }))
)
const ServiceRequest = lazy(() =>
  import('./BIDigitalReport/ServiceRequest').then(module => ({
    default: module.ServiceRequest,
  }))
)
const GuestBalance = lazy(() =>
  import('./BIDigitalReport/GuestBalance').then(module => ({
    default: module.GuestBalance,
  }))
)
const OutstandingBalance = lazy(() =>
  import('./BIDigitalReport/OutstandingBalanceReport').then(module => ({
    default: module.OutstandingBalanceReport,
  }))
)
const RoomRateListing = lazy(() =>
  import('./BIDigitalReport/RoomRateListing').then(module => ({
    default: module.RoomRateListing,
  }))
)
const GroupReservation = lazy(() =>
  import('./BIDigitalReport/GroupReservationReport').then(module => ({
    default: module.GroupReservationReport,
  }))
)
const GroupReservationSummary = lazy(() =>
  import('./BIDigitalReport/GroupReservationSummaryReport').then(module => ({
    default: module.GroupReservationSummaryReport,
  }))
)
const AuditUserLoggingSystem = lazy(() =>
  import('./BIDigitalReport/AuditUserLoggingSystemReport').then(module => ({
    default: module.AuditUserLoggingSystemReport,
  }))
)
const RoomAvailability = lazy(() =>
  import('./BIDigitalReport/RoomAvailability').then(module => ({
    default: module.RoomAvailability,
  }))
)
const DailyRevenueSummary = lazy(() =>
  import('./BIDigitalReport/DailyRevenueSummary').then(module => ({
    default: module.DailyRevenueSummary,
  }))
)
const DailyPostingDetail = lazy(() =>
  import('./BIDigitalReport/DailyPostingDetail').then(module => ({
    default: module.DailyPostingDetail,
  }))
)
const CashierCollectionSummaryReport = lazy(() =>
  import('./BIDigitalReport/CashierCollectionSummary').then(module => ({
    default: module.CashierCollectionSummary,
  }))
)
const BookingAdvancePaymentListingReport = lazy(() =>
  import('./BIDigitalReport/BookingAdvancePaymentListing').then(module => ({
    default: module.BookingAdvancePaymentListingReport,
  }))
  )
const TransactionTransferHistory = lazy(() =>
  import('./BIDigitalReport/TransactionTransferHistory').then(module => ({
    default: module.TransactionTransferHistory,
  }))
)
const EInvoiceListing = lazy(() =>
  import('./BIDigitalReport/EInvoiceListing').then(module => ({
    default: module.EInvoiceListing,
  }))
)
const EInvoiceReconciliationReport = lazy(() =>
  import('./BIDigitalReport/EInvoiceReconciliationReport').then(module => ({
    default: module.EInvoiceReconciliationReport,
  }))
)
const ConsolidatedEInvoice = lazy(() =>
  import('./BIDigitalReport/ConsolidatedEInvoice').then(module => ({
    default: module.ConsolidatedEInvoice,
  }))
)

const HotelStatus = lazy(() =>
  import('../HomeModule/Home').then(module => ({
    default: module.Home,
  }))
)
const HotelStatistic = lazy(() =>
  import('./BIAnalytics/HotelStatistic').then(module => ({
    default: module.HotelStatistic,
  }))
)
const RevenueAnalysis = lazy(() =>
  import('./BIAnalytics/RevenueAnalysis').then(module => ({
    default: module.RevenueAnalysis,
  }))
)
const RevenueAnalysisNew = lazy(() =>
  import('./BIAnalytics/RevenueAnalysisNew').then(module => ({
    default: module.RevenueAnalysisNew,
  }))
)

const SourceAnalysis = lazy(() =>
  import('./BIAnalytics/SourceAnalysis').then(module => ({
    default: module.SourceAnalysis,
  }))
)

const Top10CountryAnalysis = lazy(() =>
  import('./BIAnalytics/Top10CountryAnalysis').then(module => ({
    default: module.Top10CountryAnalysis,
  }))
)
const Top10NationalityAnalysis = lazy(() =>
  import('./BIAnalytics/Top10NationalityAnalysis').then(module => ({
    default: module.Top10NationalityAnalysis,
  }))
)
const CorpTAAnalysis = lazy(() =>
  import('./BIAnalytics/CorpTAAnalysis').then(module => ({
    default: module.CorpTAAnalysis,
  }))
)
const MarketingPersonal = lazy(() =>
  import('./BIAnalytics/MarketingPersonal').then(module => ({
    default: module.MarketingPersonal,
  }))
)
const RoomType = lazy(() =>
  import('./BIAnalytics/RoomType').then(module => ({
    default: module.RoomType,
  }))
)
const Top5TravelAgent = lazy(() =>
  import('./BIAnalytics/Top5TravelAgent').then(module => ({
    default: module.Top5TravelAgent,
  }))
)
const SegmentAnalysis = lazy(() =>
  import('./BIAnalytics/SegmentAnalysis').then(module => ({
    default: module.SegmentAnalysis,
  }))
)
const Top10Revenue = lazy(() =>
  import('./BIAnalytics/Top10Revenue').then(module => ({
    default: module.Top10Revenue,
  }))
)

const ServiceRequestAnalysis = lazy(() =>
  import('./BIAnalytics/ServiceRequestAnalysis').then(module => ({
    default: module.ServiceRequestAnalysis,
  }))
)
const HotelOccupancy = lazy(() =>
  import('./BIAnalytics/HotelOccupancy').then(module => ({
    default: module.HotelOccupancy,
  }))
)

// start new listing

const StateAnalysisByRoomNightAndRoomRevenue = lazy(() =>
  import('./BIAnalytics/StateAnalysisByRoomNightAndRoomRevenue').then(
    module => ({
      default: module.StateAnalysisByRoomNightAndRoomRevenue,
    })
  )
)

const DebtorLedgerStatus = lazy(() =>
  import('./BIAnalytics/DebtorLedgerStatus').then(module => ({
    default: module.DebtorLedgerStatus,
  }))
)

const TotalAging = lazy(() =>
  import('./BIAnalytics/TotalAging').then(module => ({
    default: module.TotalAging,
  }))
)

const TotalAmountDueByDebtorType = lazy(() =>
  import('./BIAnalytics/TotalAmountDueByDebtorType').then(module => ({
    default: module.TotalAmountDueByDebtorType,
  }))
)

const TotalAmountDueByAging = lazy(() =>
  import('./BIAnalytics/TotalAmountDueByAging').then(module => ({
    default: module.TotalAmountDueByAging,
  }))
)

const Top10CustomerByRevenue = lazy(() =>
  import('./BIAnalytics/Top10CustomerByRevenue').then(module => ({
    default: module.Top10CustomerByRevenue,
  }))
)

const TotalCollectionByDebtorTypevsOverdue = lazy(() =>
  import('./BIAnalytics/TotalCollectionByDebtorTypevsOverdue').then(module => ({
    default: module.TotalCollectionByDebtorTypevsOverdue,
  }))
)

const RegistrationCard = lazy(() =>
  import('./BIDigitalDocument/RegistrationCard').then(module => ({
    default: module.RegistrationCard,
  }))
)
const Folio = lazy(() =>
  import('./BIDigitalDocument/Folio').then(module => ({
    default: module.Folio,
  }))
)
const Deposit = lazy(() =>
  import('./BIDigitalDocument/Deposit').then(module => ({
    default: module.Deposit,
  }))
)
const BIDocument = lazy(() =>
  import('./BIDigitalDocument/BIDocument').then(module => ({
    default: module.BIDocument,
  }))
)
const DepositDocument = lazy(() =>
  import('./BIDigitalDocument/DepositDocument').then(module => ({
    default: module.DepositDocument,
  }))
)
const AdvanceForfeitDocument = lazy(() =>
  import('./BIDigitalDocument/AdvanceForfeitDocument').then(module => ({
    default: module.AdvanceForfeitDocument,
  }))
)
const ReceiptDocument = lazy(() =>
  import('./BIDigitalDocument/ReceiptDocument').then(module => ({
    default: module.ReceiptDocument,
  }))
)
const Receipt = lazy(() =>
  import('./BIDigitalDocument/Receipt').then(module => ({
    default: module.Receipt,
  }))
)
const RegistrationCardDocument = lazy(() =>
  import('./BIDigitalDocument/RegistrationCardDocument').then(module => ({
    default: module.RegistrationCardDocument,
  }))
)

const GuestRoomTransferReport = lazy(() =>
  import('./BIDigitalReport/GuestRoomTransferReport').then(module => ({
    default: module.GuestRoomTransferReport,
  }))
)
const CityLedgerTransactionListing = lazy(() =>
  import('./BIDigitalReport/CityLedgerTransactionListing').then(module => ({
    default: module.CityLedgerTransactionListing,
  }))
)
export const TypesRoute = {
  Refund: 'REFUND VOUCHER',
  AdvancePayment: 'ADVANCE PAYMENT',
  Folio: 'FOLIO',
  Receipt: 'RECEIPT',
  RefundFolio: 'REFUND',
}
export const RefundRoute = '/businessinsight/digitaldocument/folio/refund'
export const FolioRoute = '/businessinsight/digitaldocument/folio/folio'
export const DepositRoute = '/businessinsight/digitaldocument/folio/deposit'
export const ForfeitRoute = '/businessinsight/digitaldocument/folio/forfeit'
export const RefundVoucherRoute =
  '/businessinsight/digitaldocument/receipt/refund'
export const ReceiptRoute = '/businessinsight/digitaldocument/receipt/receipt'
export const RefundDepositRoute = '/businessinsight/digitaldocument/receipt/depositrefund'
export const AdvancePaymentRoute =
  '/businessinsight/digitaldocument/receipt/advancepayment'
const BusinessInsightRoutes = [
  {
    props: { exact: true, path: '/businessinsight' },
    component: <BusinessInsightList />,
  },
  // {
  //   props: { exact: true, path: '/businessinsight/analytics' },
  //   component: <BusinessAnalytics />,
  // },
  {
    props: { exact: true, path: '/analytics' },
    component: <BusinessInsightAnalytics />,
  },
  // {
  //   props: { exact: true, path: '/businessinsight/analytics' },
  //   component: <Filters />,
  // },
  {
    props: { exact: true, path: '/digitalreport' },
    component: <BusinessInsightDigitalReport />,
  },
  {
    props: { exact: true, path: '/digitaldocument' },
    component: <BusinessInsightDigitalDocument />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitaldocument/registrationcard',
    },
    component: <RegistrationCard />,
  },
  {
    props: { exact: true, path: '/businessinsight/digitaldocument/folio' },
    component: <Folio />,
  },
  {
    props: { exact: true, path: '/businessinsight/digitaldocument/deposit' },
    component: <Deposit />,
  },
  {
    props: {
      exact: true,
      path: RefundRoute,
    },
    component: <BIDocument type={TypesRoute.RefundFolio} />,
  },
  {
    props: {
      exact: true,
      path: FolioRoute,
    },
    component: <BIDocument type={TypesRoute.Folio} />,
  },
  {
    props: {
      exact: true,
      path: DepositRoute,
    },
    component: <DepositDocument type={TypesRoute.Folio} />,
  },
  {
    props: {
      exact: true,
      path: RefundDepositRoute,
    },
    component: <DepositDocument type={TypesRoute.Refund} />,
  },
  {
    props: {
      exact: true,
      path: ForfeitRoute,
    },
    component: <AdvanceForfeitDocument type={TypesRoute.Folio} />,
  },
  {
    props: {
      exact: true,
      path: ReceiptRoute,
    },
    component: <ReceiptDocument />,
  },
  {
    props: {
      exact: true,
      path: RefundVoucherRoute,
    },
    component: <ReceiptDocument type={TypesRoute.Refund} />,
  },
  {
    props: {
      exact: true,
      path: AdvancePaymentRoute,
    },
    component: <ReceiptDocument type={TypesRoute.AdvancePayment} />,
  },
  {
    props: { exact: true, path: '/businessinsight/digitaldocument/receipt' },
    component: <Receipt />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitaldocument/regcard/regcarddocument',
    },
    component: <RegistrationCardDocument />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/cashiersummary',
    },
    component: <CashierSummary />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/cashierdetaillisting',
    },
    component: <CashierDetailListing />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/salesandserviceslisting',
    },
    component: <SalesAndServicesListing />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/cityledgertransactionlisting', 
    },
    component: <CityLedgerTransactionListing />, 
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/transactionledger',
    },
    component: <TransactionLedger />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/tourismtax',
    },
    component: <TourismTaxDetail />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/consolidated-e-Invoice',
    },
    component: <ConsolidatedEInvoice />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/tourismtaxanalysis',
    },
    component: <TourismTaxAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/tourismtaxform03',
    },
    component: <TourismTaxForm03 />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/transactioncancellation',
    },
    component: <TransactionCancel />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/servicerequest',
    },
    component: <ServiceRequest />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/guestbalance',
    },
    component: <GuestBalance />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/outstandingbalance',
    },
    component: <OutstandingBalance />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/roomratelisting',
    },
    component: <RoomRateListing />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/bookingstatus',
    },
    component: <BookingStatus />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/managerreport',
    },
    component: <ManagerReport />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/managerreportbyfinancial',
    },
    component: <ManagerReportByFinancial />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/downloaddedgllisting',
    },
    component: <DownloadedGLListing />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/productionanalysisroomno',
    },
    component: <ProductionAnalysisRoomNo />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/productionanalysisratecode',
    },
    component: <ProductionAnalysisRateCode />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/productionanalysisroomtype',
    },
    component: <ProductionAnalysisRoomType />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/productionanalysistagov',
    },
    component: <ProductionAnalysisTAGov />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/productionanalysissegment',
    },
    component: <ProductionAnalysisSegment />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/productionanalysissource',
    },
    component: <ProductionAnalysisSource />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/productionanalysisstate',
    },
    component: <ProductionAnalysisState />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/productionanalysisbysaleschannel',
    },
    component: <ProductionAnalysisBySalesChannelReport />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/packages',
    },
    component: <Packages />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/roomstatus',
    },
    component: <RoomStatus />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/roomstatusmovement',
    },
    component: <RoomStatusMovement />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/inhouse',
    },
    component: <InHouseGuest />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/securitydeposit',
    },
    component: <SecurityDeposit />,
  },
  {
  props: {
    exact: true,
    path: '/businessinsight/digitalreport/TransactionTransferhistory',
  },
  component: <TransactionTransferHistory />,
  },
  {
  props: {
    exact: true,
    path: '/businessinsight/digitalreport/einvoicelisting',
  },
  component: <EInvoiceListing />,
  },
  {
  props: {
    exact: true,
    path: '/businessinsight/digitalreport/einvoicereconciliationreport',
  },
  component: <EInvoiceReconciliationReport />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/refundabledepositlisting',
    },
    component: <RefundableDepositListing />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/roomdiscerapancy',
    },
    component: <RoomDiscrepancy />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/groupreservationreport',
    },
    component: <GroupReservation />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/groupreservationsummaryreport',
    },
    component: <GroupReservationSummary />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/audituserloggingsystem',
    },
    component: <AuditUserLoggingSystem />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/roomavailability',
    },
    component: <RoomAvailability />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/debtorledgerdetail',
    },
    component: <DebtorLedgerDetail />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/debtorstatus',
    },
    component: <DebtorStatus />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/debtorledger',
    },
    component: <DebtorLedger />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/debtorAllocation',
    },
    component: <DebtorAllocation />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/debtoragingsummary',
    },
    component: <DebtorAgingSummary />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/debtoragingdetail',
    },
    component: <DebtorAgingDetail />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/statementofaccount',
    },
    component: <StatementOfAccount />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/historicalandforecast',
    },
    component: <HistoricalAndForecast />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/roommaintenance',
    },
    component: <RoomMaintenance />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/dailyrevenuesummary',
    },
    component: <DailyRevenueSummary />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/dailypostingdetail',
    },
    component: <DailyPostingDetail />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/cashiercollectionsummary',
    },
    component: <CashierCollectionSummaryReport />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/revenueanalysisew',
    },
    component: <RevenueAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/revenueanalysis',
    },
    component: <RevenueAnalysisNew />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/hotelstatus',
    },
    component: <HotelStatus />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/hotelstatistic',
    },
    component: <HotelStatistic />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/sourceanalysis',
    },
    component: <SourceAnalysis />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/top10countryanalysis',
    },
    component: <Top10CountryAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/top10nationalityanalysis',
    },
    component: <Top10NationalityAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/corp&govt&taanalysis',
    },
    component: <CorpTAAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/marketingpersonnelanalysis',
    },
    component: <MarketingPersonal />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/revenuebyroomtypeanalysis',
    },
    component: <RoomType />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/top5oatanalysis',
    },
    component: <Top5TravelAgent />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/segmentanalysis',
    },
    component: <SegmentAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/top10revenunebyteansaction',
    },
    component: <Top10Revenue />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/servicerequestbydepartment',
    },
    component: <ServiceRequestAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/hoteloccupancy',
    },
    component: <HotelOccupancy />,
  },

  //start new listing
  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/StateAnalysisByRoomNightAndRoomRevenue',
    },
    component: <StateAnalysisByRoomNightAndRoomRevenue />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/DebtorLedgerStatus',
    },
    component: <DebtorLedgerStatus />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/TotalAging',
    },
    component: <TotalAging />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/TotalAmountDueByDebtorType',
    },
    component: <TotalAmountDueByDebtorType />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/TotalAmountDueByAging',
    },
    component: <TotalAmountDueByAging />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/Top10CustomerByRevenue',
    },
    component: <Top10CustomerByRevenue />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/TotalCollectionByDebtorTypevsOverdue',
    },
    component: <TotalCollectionByDebtorTypevsOverdue />,
  },

  {
    props: {
      exact: true,
      path: '/businessinsight/analytic/GuestRoomTransferReport',
    },
    component: <GuestRoomTransferReport />,
  },
  {
    props: {
      exact: true,
      path: '/businessinsight/digitalreport/BookingAdvancePaymentListingReport',
    },
    component: <BookingAdvancePaymentListingReport />,
  },
]

export default BusinessInsightRoutes
